import React, { FC } from 'react';

import { useUpdateOTLds } from '@phx-husky/use-update-ot-lds';

import { Container, Row, Col, Layout } from 'layout';

import headerProps from './header-mock.json';

import { LdsPageProps } from './models';
import 'styles/main.scss';

const LdsPage: FC<LdsPageProps> = ({ pageContext }) => {
  const { body, businessId, versionId, platform } = pageContext;

  const { updatedLdsContent, isCheckingLdsVersion } = useUpdateOTLds({
    baseUrl: process.env.GATSBY_LDS_BASE_URL as string,
    businessId,
    versionId,
    platform,
  });

  return (
    <Layout {...headerProps}>
      {!isCheckingLdsVersion && (
        <section className="lds-page">
          {/* {titleParsed} */}
          <Container fluid>
            <Row>
              <Col lg="8">
                <div
                  dangerouslySetInnerHTML={{
                    __html: (updatedLdsContent ?? body).replace(/(<p>&nbsp;<\/p>)+/g, ''),
                  }}
                />
              </Col>
            </Row>
          </Container>
        </section>
      )}
    </Layout>
  );
};

export default LdsPage;
